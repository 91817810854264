import { SpeciesReference } from '../types/global';

export const speciesReference: SpeciesReference = {
  'f4372701-68d2-4459-bdba-b3be753e80d4': {
    name: 'Dog',
    displayName: 'dog',
    litterName: 'Puppy',
    mixedBreedSizes: [
      {
        type: 'Small',
        weight: 'Less than 10kg',
        mixedBreedId: '799e33e0-e084-4922-8575-60b31db1775d',
      },
      {
        type: 'Medium',
        weight: '10kg to 20kg',
        mixedBreedId: '8836da7c-196e-4074-86a4-ebcc875f8a85',
      },
      {
        type: 'Large',
        weight: 'More than 20kg',
        mixedBreedId: '20141358-76d5-4709-9ff4-c6c3858ea102',
      },
    ],
  },
  'c2597178-071f-4940-825d-4d7351b5ffa4': {
    name: 'Cat',
    displayName: 'cat',
    litterName: 'Kitten',
    mixedBreedId: 'd470cbb3-f62f-4b1f-9631-836f32bffaaf',
  },
  '717c7e3d-09cc-4e66-807a-3527541e51d1': {
    name: 'Rabbit',
    displayName: 'rabbit',
    litterName: 'Kit',
  },
  '636b067c-dd3e-480d-87e7-d59df08c221c': {
    name: 'Equine',
    litterName: 'Foal',
    displayName: 'horse',
  },
};
