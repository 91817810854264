export const scrollToElement = (
  elements: (React.RefObject<HTMLElement> | string)[],
  offset: number | null | undefined = -50
) => {
  for (const element of elements) {
    let targetElement: HTMLElement | null = null;

    if (typeof element === 'string') {
      targetElement = document.getElementById(element);
    } else if (element.current) {
      targetElement = element.current;
    }

    if (targetElement) {
      const rect = targetElement.getBoundingClientRect();
      const absoluteTop = window.scrollY + rect.top;
      window.scrollTo({
        top: absoluteTop + (offset ?? -50),
        behavior: 'smooth',
      });
      break;
    }
  }
};
